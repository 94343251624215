import { PATHS } from '../config'
import { PAGE_SLUG } from '../constants'

const SEPARATOR = '/'

const TAG_PERMALINK = 'tag'
const CATEGORY_PERMALINK = 'category'
const PAGE_PERMALINK = 'page'

export function buildArticlePermalink({ year, month, day, slug, page }) {
  const parts = [
    year,
    String(month).padStart(2, '0'),
    String(day).padStart(2, '0'),
    slug,
  ]

  const hasPage = page && page > 1

  if (hasPage) {
    parts.push(page.toString())
  }

  const uri = join(...parts)

  return {
    href: hasPage ? PATHS.ARTICLES_PAGED : PATHS.ARTICLES,
    as: uri,
  }
}

export function buildDayPermalink({ year, month, day, page }) {
  const uri = join(
    year,
    String(month).padStart(2, '0'),
    String(day).padStart(2, '0'),
    ...buildPageParts(page),
  )

  return {
    href: page > 1 ? PATHS.DAY_ARCHIVES_PAGE : PATHS.DAY_ARCHIVES,
    as: uri,
  }
}

export function buildMonthPermalink({ year, month, page }) {
  const uri = join(
    year,
    String(month).padStart(2, '0'),
    ...buildPageParts(page),
  )

  return {
    href: page > 1 ? PATHS.MONTH_ARCHIVES_PAGE : PATHS.MONTH_ARCHIVES,
    as: uri,
  }
}

export function buildYearPermalink({ year, page }) {
  const uri = join(year, ...buildPageParts(page))

  return {
    href: page > 1 ? PATHS.YEAR_ARCHIVES_PAGE : PATHS.YEAR_ARCHIVES,
    as: uri,
  }
}

export function buildTagPermalink({ slug, page = 1 }) {
  const parts = [
    TAG_PERMALINK,
    slug,
  ]

  const hasPage = page && page > 1

  if (hasPage) {
    parts.push(PAGE_SLUG, page.toString())
  }

  const uri = join(...parts)

  return {
    href: PATHS.TAG_ARCHIVES,
    as: uri,
  }
}

export function buildCategoryPermalink({ slug, slugs, page = 1 }) {
  const parts = [
    CATEGORY_PERMALINK,
    ...(slugs ? slugs : [slug]),
  ].filter(item => item)

  const hasPage = page && page > 1

  if (hasPage) {
    parts.push(PAGE_SLUG, page.toString())
  }

  const uri = join(...parts)

  return {
    href: PATHS.CATEGORY_ARCHIVES,
    as: uri,
  }
}

export function buildPagePermalink({ slugs, slug }) {
  const parts = [
    ...(slugs ? slugs : [slug]),
  ].filter(item => item)

  const uri = join(...parts)

  if (Object.values(PATHS).includes(uri)) {
    return {
      href: uri,
      as: uri,
    }
  }

  return {
    href: PATHS.PAGES,
    as: join(PAGE_PERMALINK, ...parts),
  }
}

function buildPageParts(page = 1) {
  if (page <= 1) {
    return []
  }

  return ['page', page.toString()]
}

function join(...parts) {
  return SEPARATOR + parts.filter(entry => !!entry).join(SEPARATOR)
}
